











































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api";
import { ElForm } from "element-ui/types/form";
import { SupplierCreateOrUpdateDto, SupplierDto } from "@/api/appService";

@Component({
  name: "SupplierEdit",
})
export default class SupplierEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  loading = true;
  show = false;

  form: SupplierCreateOrUpdateDto = {};
  defaultData: SupplierCreateOrUpdateDto = {
    id: 0,
  };

  save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      if (this.dataId) {
        api.supplier.update({ body: this.form }).then(() => {
          this.show = false;
          this.$message.success("更新成功");
        });
      } else {
        api.supplier.create({ body: this.form }).then(() => {
          this.show = false;
          this.$message.success("新建成功");
        });
      }
    });
  }

  @Watch("show")
  onShowChange(value: boolean) {
    if (value) {
      if (this.dataId) {
        this.loading = true;
        api.supplier
          .get({ id: this.dataId })
          .then((res: SupplierDto) => {
            if (res) {
              this.form = {
                supplierName: res.supplierName,
                realName: res.fkUser?.realName,
                mobilePhone: res.fkUser?.mobilePhone,
                email: res.fkUser?.emailAddress,
                id: res.id,
              };
            } else {
              this.form = { ...this.defaultData };
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.form = { ...this.defaultData };
        this.loading = false;
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  linkRule = {
    supplierName: [
      {
        required: true,
        message: "请输入供应商名称",
        trigger: "blur",
      },
    ],
    realName: [
      {
        required: true,
        message: "请输入联系人",
        trigger: "blur",
      },
    ],
    mobilePhone: [
      {
        required: true,
        message: "请输入联系电话",
        trigger: "blur",
      },
    ],
    email: [
      {
        required: true,
        message: "请输入联系邮箱",
        trigger: "blur",
      },
      {
        type: "email",
        message: "请输入正确的邮箱",
        trigger: "blur",
      },
    ],
  };

  get title() {
    if (this.dataId) {
      return `编辑`;
    } else {
      return "新建";
    }
  }

  cancel() {
    this.show = false;
  }
}
