










































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "ImportExcel" })
export default class ImportExcel extends Vue {
  @Prop({ required: false, default: "模板上传" })
  title!: string;

  @Prop({ required: true })
  url!: string;

  form = {
    show: false,
    submitting: false,
    data: {
      Year: "",
      Month: "",
      Target: 0,
    },
    rules: {},
  };

  get uploadHeaders() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      "Abp.ManagePlatform": true,
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
    };
  }

  uploadFile() {
    this.form.show = true;
  }


  submitUpload() {
    (this.$refs.upload as any).submit();
  }

  handleUploadSuccess(res: any) {
    if (res.result.importResults && res.result.importResults.length > 0) {
      this.jumpDetail(res.result.importResults);
      this.form.show = false;
    }
  }

  handleUploadError(res: any) {
    const json = JSON.parse(res.message);
    this.$message({
      type: "error",
      message: `上传失败!${json.error.message}`,
    });
    this.form.show = false;
  }
  jumpDetail(table: any) {
    this.$router.push({
      name: "uploadTemplateDetail",
      params: {
        table: table,
      },
    });
  }
}
