






























































































import { Component, Vue } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView.vue";
import ImportExcel from "@/components/ImportExcel.vue";
import api from "@/api";
import { SupplierDto } from "@/api/appService";
import SupplierEdit from "@/views/purchase/supplierManagement/edit.vue";

@Component({
  name: "SupplierList",
  components: {
    SupplierEdit,
    PagedTableView,
    ImportExcel
  }
})
export default class SupplierList extends Vue {
  queryForm = {
    supplierName: undefined,
    mobilePhone: undefined,
    realName: undefined
  };
  supplierId = 0;

  // 获取表数据
  fetchData(params: any) {
    return api.supplier.getAll(params);
  }

  handleCreate() {
    this.supplierId = 0;
    (this.$refs.editForm as any).show = true;
  }

  handleEdit(row: SupplierDto) {
    this.supplierId = row.id!;
    (this.$refs.editForm as any).show = true;
  }

  handleEnableOrDisable(row: SupplierDto) {
    return row.fkUser && row.fkUser.isActive ? api.user.deActivate({ body: { id: row.userId } }) : api.user.activate({ body: { id: row.userId } });
  }

  setSalePriceVisible(row: SupplierDto) {
    api.supplier.setSalePriceVisible({ body: { id: row.id } });
  }
};
